import { ISubscription } from 'src/app/constants/subscription';
// dep
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ChangeDetectorRef } from '@angular/core';

// app
import { SessionService } from 'src/app/services/session.service';
import { ManagePlanService } from '../services/manage-plan.service';
import { ModalGetAddOn } from '../../modules/modal-get-addon/modal-get-addon.component';
import { ModalService } from 'src/app/services/modal.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { IPricingObject, PackageEnum } from '../constants/pricing';
import { BaseComponent } from 'src/app/components/base.component';
import { PaymentMethodComponent } from 'src/app/payment-method/payment-method.component';
import { GROUP_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-manage-plan',
  templateUrl: './manage-plan.component.html',
  styleUrls: ['./manage-plan.component.scss']
})
export class ManagePlanComponent extends BaseComponent implements OnInit {
  @Input() title = 'Manage Add-Ons';
  @Input() manageSubscription = false;
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Update Add-Ons';
  @Output() onClose = new EventEmitter();
  @Output() onClosePlanBilling = new EventEmitter();

  @ViewChild(PaymentMethodComponent) 
  public payComponent!: PaymentMethodComponent;

  public totalAmount = 0;
  public pricingObject: IPricingObject;
  public showNewPricingFlow = false;
  public supportEmail = 'support@maplabs.com'; // <- TODO: whitelabels have a different emails
  public paymentErrorMsg = '';

  // this flag system should be replaced with something better
  public isPaymentInfo = false;
  public isProcessingPayment = false;
  public paymentError = false;
  public paymentSuccess = false;
  public isLoading = true;

  constructor(
    private _mngPlanS: ManagePlanService,
    private _sessionS: SessionService,
    private _modalS: ModalService,
    private _snackS: SnackbarService,
    private _paymentsS: PaymentsService,
    private _cdRef: ChangeDetectorRef
  
  ) { 
    super()
  }

  async ngOnInit() : Promise<void> {
    document.querySelector('body').style.overflow = 'hidden';
    if (this.manageSubscription) { 
      await this._mngPlanS.reloadPricingObject();
    }
    this._subscribeSafe(this._mngPlanS.pricingObject$, 
      data => {
        if (data) {
          this.pricingObject = {...data};
          console.log('pricingObject: ', this.pricingObject);
          
        }
      })
      
      this.isLoading = true;
      
      // darklaunch functionality
      const {features} = this._sessionS.getSession();
      this.renderModal(features.final.updatedPricingModal);
      this.isLoading = false;
    }
    
    ngDoCheck(): void {
      this.updateTotal();
      this._cdRef.detectChanges();
    }
    
    renderModal(show: boolean): void {
      if (show) {
        this.showNewPricingFlow = true;
        this.isPaymentInfo = true;
    } else {
      this.showNewPricingFlow = false;
      this._modalS.openModal(
        ModalGetAddOn,
        {
          title: 'Add-On: Bulk Actions and Roll-Up Reports',
          message: '',
          buttons: {
            'cancel': 'Maybe Later',
            'accept': 'Get Add-On'
          }
        }, 
        { 
          config: { 
            width: '830px' 
          }
        }
      )
      this.close();
    }
  }

  updateTotal(): void {
    let total = 0;

    this.pricingObject?.availableAddons?.forEach(addon => {
      if (addon.isActive) {
        total += !isNaN(addon.totalPrice) ? addon.totalPrice : 0;
      }
    });

    if (this.manageSubscription) {
      const partial = this.pricingObject?.unitPrice * this.pricingObject?.totalLocations;
      total += !isNaN(partial) ? partial : 0;
    }
    
    this.totalAmount = total;
    this._cdRef.detectChanges();
  }

  displayAddonsInSummary(): boolean {
    return this.manageSubscription && this.pricingObject?.availableAddons.filter(a => a.isActive)?.length > 0
  }

  next(): void {
    this.manageSubscription ? this.payComponent.handlePay() : this.closeAndUpdateAddons();
  }
  
  // TODO: refactor once we have more than one addon, to work dynamically
  closeAndUpdateAddons(): void {
    this._mngPlanS.updateAddons(this.pricingObject?.availableAddons)
    .subscribe(response => {
      if (response.packages.pkg_bulk_actions.isTerminationScheduled) {
        this._snackS.openSuccess('The add-on Bulk updates has been successfully removed, it will be '+
                                 'available until the end of the current billing cycle.');
      } else {
        this._snackS.openSuccess('The add-on Bulk Updates has been successfully turned on, you can start using it right away.');
      }

      this.close(true);
    }, error => {
      // handle the error and prevent closing the modal
      this._snackS.openError('There has been an error while trying to update the add-ons status.');
        console.error(error);
    });
  }

  close(event = false): void {
    document.querySelector('body').style.overflow = 'auto';
    this.onClose.emit(event);
  }

  closePlanBilling(event = false): void {
    if (event && this.paymentSuccess) {
      this.onClosePlanBilling.emit(event);
    }
  }


  /**
   * Ask the backend to create the First Payment, using the packages selected by the user.
   */
  public createFirstPayment = async  (confirmationTokenId : string) : Promise<any> => {
    const {gid} = this._sessionS.getSession();
    // TODO: Set packages to enable on first payment
    // extract from this.pricingObject?.availableAddons array each name and add it to an array
    const selectedPackages : (keyof ISubscription['packages'])[] = [];
    // manually push the core package, so in case no add-ons are added, we don't get the "none" error from the backend
    // selectedPackages.push(PackageEnum.CORE);
    this.pricingObject?.availableAddons?.map(addon => {
      switch (addon.name) { // mapping add-on names to PackageEnum values, add more as we include more add-ons
        case 'pkg_bulk_actions': 
          if (addon.isActive) {
            selectedPackages.push(PackageEnum.BULK_ACTIONS);
          }
          break;
        }
    });

    this.paymentError = false; // <- flag reset
    this.isPaymentInfo = false;
    this.isProcessingPayment = true
    this.confirmText = 'Processing';

    return await this._paymentsS.startFirstPayment(gid, confirmationTokenId, selectedPackages);
  }

  /**
   * Executes if an error exists when attempting the payment
   */
  public paymentErrorExists = async (msg: string) : Promise<void> =>  {
    this.paymentErrorMsg = msg;
    this.confirmText = 'Pay Now';
    this.paymentError = true;
    this.isPaymentInfo = true;
    this.isProcessingPayment = false;
  }

  /**
   * Polls until the subscription status becomes PAID
   */
  public afterPaymentOk = async () : Promise<void>  => {  
    // Is ensured that the payment succeeded, so this loop will eventually finish
    const s = this._sessionS;
    do {
      await s.refresh();
    } while(s.getSession().subscription.status !== GROUP_SUBSCRIPTION_TYPE.PAID);   
    this._mngPlanS.reloadPricingObject();
    // Payment sucessfully done at this point.

    this.isProcessingPayment = false;
    this.paymentSuccess = true;
    this.cancelText = 'Go to Plan and Billing'
    this.confirmText = 'Close';
    // TODO: Alert user payment finished / close dialog
  }


}
