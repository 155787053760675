// dep
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

// app
import { GoogleAttributes } from '../../../constants/google/attributes';
import { ModalAttributeUrlComponent } from '../../modal-attribute-url/modal-attribute-url.component';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-location-url-attributes',
  templateUrl: './location-url-attributes.component.html',
  styleUrls: ['./location-url-attributes.component.scss']
})
export class LocationUrlAttributesComponent implements OnInit {

  @Input() attributes;
  @Input() isEdit = true;
  @Output() onUpdate = new EventEmitter<boolean>();

  title: string = null;

  constructor(private dialog: ModalService) {
  }

  ngOnInit() {

    this.attributes = this.attributes?.
      filter(o => o.attributeId.startsWith('url')).
      filter(o => o.attributeId !== 'url_order_ahead' && o.attributeId !== 'url_reservations')
  }

  setTitle(title : string) : string {
    this.title = title;
    return title;
  }

  openUrlAttributes(attributeUrl: GoogleAttributes) : void {
    this.dialog.openGenericModal(ModalAttributeUrlComponent,
      { attribute: attributeUrl, title: this.title },
      res => {
        if (res!= '') {
          this.onUpdate.emit(true);
        }        
      },
      890);
  }

}
