// dep
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, zip, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';

// app
import { ACCOUNTS, GROUPS, LOCATIONS, POST_MANAGEMENT_GROUP, REPORTS, PROTOCOLS, WIDGET_INFO } from '../constants/firestore/collections';
import { Queue } from '../constants/firestore/enqueue';
import { LocationRef } from '../constants/firestore/location-object';
import SavedLocation from '../constants/firestore/saved-location';
import { WeekDays, WEEK_DAYS } from '../constants/google/week-days';
import { IServiceArea } from './../constants/service-area';
import { HoursAmPmPipe } from '../pipes/hours-am-pm.pipe';
import { InsightsService } from './insights.service';
import { ReviewsService } from './reviews.service';
import { ApiResponse /*, Pagination*/ } from '../constants/api-response';
import { PostService } from './post.service';
import { ObservationService } from './observation.service';
import { Pageable } from '../constants/pageable';
import { GoogleService } from './google.service';
import { ServiceData, ServiceList, Service } from '../constants/google/service-list';
import { DatesService } from "../services/dates.service";
import { environment as ENV} from '@environment';
import { ObservationsDTO } from '../constants/firestore/observation';
import { SessionService } from './session.service';
import { NotificationService } from './notification.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from '../constants/notifications';
import { Messages, string_message } from '../constants/messages';
import { AccountService } from './account.service';

type ArrayItemType<T extends any[]> = T extends (infer U)[] ? U : never;

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public loading = false; // TODO: Really used?

  private _accountAllLocations = new BehaviorSubject<SavedLocation[]>([]);
  private _location  = new BehaviorSubject<SavedLocation | null>(null);
  private _paginate  = new BehaviorSubject({ size: 10, page: 1 });

  public readonly location$ = this._location.asObservable();
  public readonly accountAllLocations$ = this._accountAllLocations.asObservable();
  public readonly paginate$ = this._paginate.asObservable(); 

  /**
   * Will trigger when some location changed (e.g. by an upgrade), to signal
   * that locations should be refreshed. 
   */
  public someLocationChanged$ = new BehaviorSubject<null>(null)

  constructor(
    private _sessionS : SessionService,
    private _afs: AngularFirestore,
    private _http: HttpClient,
    private _googleS: GoogleService,
    private _reviewsService: ReviewsService,
    private _insightsService: InsightsService,
    private _postService: PostService,
    private _observationS: ObservationService,
    private _dateS: DatesService,
    private _notificationS : NotificationService,
    private _accountS : AccountService
  ) {
  }

  getRegionCode(result: any): string {
    return (result?.location?.address?.regionCode || 
            result?.location?.serviceArea?.regionCode || 
            navigator.language.split('-')[1])
  }

  fetchAccountLocationsPaginated(gid: string, accountId: string, pageable: Pageable, locationIds?: string[]): Promise<any> {    
    
    // deprecated
    //
    // const params = new HttpParams()
    //   .set('page', pageable.page.toString())
    //   .set('pageSize', pageable.size.toString())
    //   .set('locationIds', locationIds.join(','));

    // return this._http.get(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/all`, { params });

    const body = {
      page: pageable.page,
      pageSize: pageable.size,
      locationIds: locationIds
    };

    return this._http.post(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/all`, body).toPromise();
    // return this._http.get(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/all`, { params }).toPromise(); 
  }

  getLocationsIdsByStringQuery(gid: string, queryString: string): Promise<{ accounts : {accountId : string, locations : { locationId : string}[]}[]}> {
    return this._http.post(`${ENV.apiUrl}/v2/search/gid/${gid}/account-locations?query=${queryString}`, {'query': queryString}).toPromise() as any
  }


  // TODO: Optimize a lot of fetchLocations callers that doesn't need the entire location, 
  // should add the specific fields they need and a singature here like fetchMultipleLocations
  fetchLocation(gid : string, accountId : string, locationId : string): Promise<SavedLocation> {
    return this.getRef(gid, accountId, locationId).toPromise()
  }

  // TODO: move all .getRef(...).toPromise() calls to .fetchLocation(), also change multiple calls to getRef 
  // to a single getMultipleLocations
  getRef(gid : string, accountId : string, locationId : string): Observable<SavedLocation> {
    return this._http.get<SavedLocation>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}`)
  }


  /**
   * Fetchs all gid locations or only a subset of them (if locationRefs is specified)
   * If fields is specified, only those fields will be fetched, if not, all fields should be.
   */
  async fetchMultipleLocations<F extends (keyof SavedLocation)[] | undefined, 
                               R extends (F extends undefined ? SavedLocation : Pick<SavedLocation, ArrayItemType<F>>[])>
                              (gid : string, locationRefs? : LocationRef[], fields? : F) : Promise<R> {
    return await this._http.post<R>(`${ENV.apiUrl}/v2/locations/by-gid/${gid}` + 
                                    (fields ? '?fields=' + fields.join(',') : ''),
                                    (locationRefs ? {'locations' : locationRefs} : undefined)).toPromise()
  }


  getPendingMask(gid: string, accountId: string, locationId: string): Promise<any> {
    return this._http.get<SavedLocation>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}/pendingMask`).toPromise();
  }

  async getObservations(gid : string, accountId : string, locationId : string): Promise<ObservationsDTO | null> {
    return (await this.fetchLocation(gid, accountId, locationId)).googleObservations || null
  }

  isLocked(locationId: string): Observable<{locked : boolean, location_id : string, lock_count : number}> {
    return this._http.get<any>(`${ENV.apiUrl}/v2/locations/${locationId}/locked`);
  }

  // reset(): void {
  //   // TODO: Reassignation instead of .next([])/.next(null) ? Why?
  //   // A (wrong, leaking) way to "desubscribe" previous subscribers? as
  //   // observables are accessed thru the locations() and location() getters
  //   this._locations = new BehaviorSubject([]);
  //   this._location  = new BehaviorSubject(null);
  // }

  reset() : void {
    this._accountAllLocations.next([]);
    this._location.next(null);
  }

  async fetchAccountLocationsAndEmit(gid : string, accountId: string): Promise<void> {
    try {
      this.loading = true;
      this._accountAllLocations.next(await this.fetchAccountLocations(gid, accountId));
    } finally {
      this.loading = false;
    }
  }

  public getAccountLocationsCached() : SavedLocation[] {
    return this._accountAllLocations.getValue();
  }


  fetchLocationsExistence(locations: (LocationRef & {gid : string})[]): Promise<(LocationRef & { gid : string
                                                                                                 locationName : string
                                                                                                 exist : boolean })[]> { 
    return this._http.post<any>(`${ENV.apiUrl}/v2/locations/byIds`, locations).toPromise()
  }

  setPaginate(paginate: {size: number, page: number}): void {
    return this._paginate.next(paginate);
  }

  async get(gid: string, accountId: string, locationId: string): Promise<void> {
    try {
      this.loading = true;
      const loc = await this.fetchLocation(gid, accountId, locationId);
      this._location.next(loc);
    } finally {
      this.loading = false;
    }
  }


  async addNewLocations(accountId : string, locations: {locationId : string, locationName : string}[]/*, gidExternalGrade? : string*/) : Promise<boolean> {
    // TODO: Should be executed by a single endpoint call. 
    try {
      const r = await Promise.all(locations.map(loc => this._addNewLocation(accountId, loc.locationId, loc.locationName /*, gidExternalGrade*/))); 
      return r.every(rr => !!rr);
    } catch(err) {
      console.error(err)
      return false;
    } finally {
      this.notifySomeLocationChanged();
    }
  }

  notifySomeLocationChanged() : void {
    this.someLocationChanged$.next(null);
  }

  /**
   * Deletes a Location and references to it.
   * If the account that contains the location has no more locations, the account is also deleted.
   * TODO: Migrate to backend, all of this should be done on a backend endpoint that 
   *       who must also accept multiple locations. 
   * @return true if the account was also deleted, false if not. 
   */
  async deleteLocation(gid: string, accountId: string, loc : Pick<SavedLocation, 'locationId' | 'location' | 'locationName'>,
                       opts : {notifyChange? : boolean, deleteEmptyAccount? : boolean} = {}): Promise<boolean> {
    const {locationId} = loc;
   
    await Promise.all([this._deleteReferencesToLocation(gid, locationId),
                       this._http.delete(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}`).toPromise()]);

    // Add delete notification
    const locationAddress = this.formatAddress(loc.location.address);
    const meta = this._notificationS.getMetaTypeLog(TYPE_LOG_LOCATION, { accountId, 
                                                                         address: locationAddress, 
                                                                        ...loc })
    await this._notificationS.saveNotification(string_message(Messages.notifications.LOCATION_TOGGLE, 
                                                             [loc.locationName, locationAddress, DELETE_DATA]),
                                               NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta);

    if(!('deleteEmptyAccount' in opts) || opts.deleteEmptyAccount) {
      const accountLocations = await this.fetchAccountLocations(gid, accountId);
      if(!accountLocations) {
        try {
          await this._accountS.delete(gid, accountId);
          return true
        } catch(err) {
          console.error(`Error deleting Account after deleting locations: gid=${gid} accountId=${accountId}`, err);
        }
      }
    }

    if(!('notifyChange' in opts) || opts.notifyChange)
      this.notifySomeLocationChanged()

    return false
  }


  async deleteReportLocation(locationsToRemove : LocationRef[], gid: string, reportId: string): Promise<void> {

    const reportRef  = this._afs.collection(GROUPS).doc(gid).collection(REPORTS).doc(reportId);
    const reportData = (await reportRef.get().toPromise()).data();

    const accountIds = locationsToRemove.map(loc => loc.accountId)
    const notDuplicatedAccountIds = [... new Set(accountIds)]

    const accountsCopy = [...reportData?.accounts]

    for (const accountId of notDuplicatedAccountIds) {
      const reportAccount = accountsCopy.find(account => account.accountId == accountId);
      const reportAccountIndex = accountsCopy.indexOf(reportAccount);
      const locationsAccount = locationsToRemove.filter(loc => loc.accountId == accountId);
      const locationsIdRemove = locationsAccount.map(loc => loc.locationId)

      const newReportLocations = []

      for (const loc of reportAccount.locations){
        const isLocationForRemove = !locationsIdRemove.includes(loc['locationId'])
        if(isLocationForRemove) 
           newReportLocations.push(loc)
      }

      if (newReportLocations.length > 0){
        accountsCopy[reportAccountIndex].locations = newReportLocations;
      } else{
        accountsCopy.splice(reportAccountIndex, 1);
      }
    }
    reportRef.update({'accounts': accountsCopy})
  }

  // TODO: This should be done on backend side using a single endpoint. 
  // TODO: accountId is not part of the args
  private async _deleteReferencesToLocation(gid : string, locationId : string): Promise<void> {
    for(const coll of [POST_MANAGEMENT_GROUP, 
                       REPORTS, 
                       PROTOCOLS]) {

      const ref = this._afs.collection(GROUPS).doc(gid).collection(coll);
      const items = (await ref.get().toPromise()).docs
      for (const item of items) {
        const data = item.data();
        const id   = item.id;

        // FIXME: BUG: This logic seems VERY broken, as newData.accounts is the
        // same array as data.accounts, mutated by analyzeAccounts. When compared
        // they are always the same array. 
        const newData = Object.assign({}, data)
        this._analyzeAccounts(newData, locationId);
        if (newData.accounts?.length) {
          if (newData.hasOwnProperty('accounts') && newData.accounts.length !== data.accounts.length) {
            await ref.doc(id).update(newData)
          }
        } else {
          await ref.doc(id).delete()
        }
      }  
    }
  }

  private _analyzeAccounts(item : firebase.firestore.DocumentData, locationId : string) {
    if (item.hasOwnProperty('accounts')) {
      const accounts = item.accounts;
      const newAccounts = accounts.filter(account => {
        if (account.hasOwnProperty('locations')) {
          const locations = account.locations;
          const newLocations = locations.filter(location => location.locationId !== locationId);
          if (newLocations.length !== locations.length) {
            account.locations = newLocations;
          }
          if (newLocations.length !== 0) {
            return account;
          }
        }
      });
      if (newAccounts.length !== accounts.length) {
        item.accounts = newAccounts;
      }
  }
}

  private async _addNewLocation(accountId : string, locationId: string, locationName : string /*, gidExternalGrade? : string*/): Promise<boolean> {
    // const gid = gidExternalGrade || this._sessionS.getSession().gid;
    // const isExternal = !!gidExternalGrade

    const {gid} = this._sessionS.getSession();

    // TODO: Modify the backend-endpoint so locationName is taken from the location
    // fetched from google, and always set lockedOn = null. Only pass {locationId, accountId, gid}
    const location = {
      locationName,
      lockedOn: null,
      gid,
      accountId,
      locationId
    };

    try {
      await this._http.post(`${ENV.apiUrl}/v2/locations/add`, location).toPromise();
      await this.locationRefreshAllDeps(accountId, locationId, gid /*, isExternal*/)
      return true;
    } catch (err) {
      console.error(`Error adding new location locationId=${locationId} accountId=${accountId}`, err);
      return false
    }
  }

  checkLocation(gid: string, accountId: string, locationId: string): Observable<any> {
    return this._http.get(`${ENV.apiUrl}/v2/locations/check/gid/${gid}/account/${accountId}/location/${locationId}`);
  }

  checkService(gid: string, accountId: string, locationId: string): Observable<ApiResponse> {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list`) 
  }

  saveInsights(accountId: string, locationId : string): Observable<Object> {
    return this._insightsService.saveInsights(accountId, locationId);
  }

  saveReviews(accountId: string, locationId : string /*, isExternal = false*/): Observable<Object> {
    return this._reviewsService.saveReviews(accountId, locationId /*, isExternal*/);
  }

  saveV3Posts(gid: string, accountId: string, locationId: string): Promise<void> {
    return this._postService.saveV3All(gid, accountId, locationId);
  }

  saveObservations(accountId: string, locationId : string): Observable<Object> {
    return this._observationS.save(accountId, locationId);
  }

  saveServices(accountId: string, locationId : string): Observable<any> {
    return this._googleS.saveServices(accountId, locationId);
  }

  saveMenu(accountId: string, locationId : string): Promise<any> {
    return this._googleS.saveMenu(accountId, locationId).toPromise()
  }

  update(gid : string, accountId : string, locationId : string, data): Observable<SavedLocation> {
    return this._http.post<SavedLocation>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}/save`, data);
  }

  initLocationEdit(gid : string, accountId : string, locationId : string, locationEdit): Observable<SavedLocation> {
    return this.update(gid, accountId, locationId, { locationEdit });
  }

  organizeServiceList(serviceList: ServiceList[], categories: { categoryId: string, displayName: string, primary: boolean, 
                      serviceTypes: { displayName: string, serviceTypeId: string }[] }[]): ServiceData[] {
    const dataSource: ServiceData[] = categories.map(ac => {
      return {
        categoryId: ac.categoryId,
        displayName: ac.displayName,
        principal: ac.primary,
        services: serviceList.reduce((result: Service[], sl, i) => {
          if (sl.freeFormServiceItem) {
            sl.freeFormServiceItem.categoryId = sl.freeFormServiceItem.categoryId || sl.freeFormServiceItem.category;
            if (sl.freeFormServiceItem.categoryId === ac.categoryId) {  
              result.push({
                isFreeFormServiceItem: true,
                positionEdit: i,
                isOffered: sl.isOffered,
                serviceTypeId: null,
                description:  sl.freeFormServiceItem.label.description,
                displayName:  sl.freeFormServiceItem.label.displayName,
                languageCode: sl.freeFormServiceItem.label.displayName,
                price: sl.price
              })
            }
          } else if (sl.structuredServiceItem) {
            const serviceType = ac.serviceTypes?.find(st => st.serviceTypeId === sl.structuredServiceItem.serviceTypeId)
            if (serviceType) {
              result.push({
                isFreeFormServiceItem: false,
                positionEdit: i,
                isOffered: sl.isOffered,
                serviceTypeId: serviceType.serviceTypeId,
                description: sl.structuredServiceItem.description,
                displayName: serviceType.displayName,
                languageCode: null,
                price: sl.price
              })
            }
          }
          return result;
        }, [])
      }
    });
    return dataSource;
  }


  convertPriceListType(type: string): string {
    type = type.toLowerCase();
    if (type === 'jobs') {
      type = 'Jobs';
    }

    if (type === 'services') {
      type = 'Services';
    }

    if (type === 'menu') {
      type = 'Menu';
    }

    return type;
  }

  getReviewSummary(gid, accountsId, locationsIs): Observable<any> {
    const data = {
      "gid": [gid],
      "accountId": accountsId,
      "locationId": locationsIs
    }
    
    return this._http.post(`${ENV.apiUrl}/v2/locations/global-review-summary`, data);
  }


  review_summary(gid : string, locations: LocationRef[]): any {
    if (!gid) {
      gid = this._sessionS.getSession().gid;
    }

    const normalizeSummary = (rs) => {
        if(!rs || !('googleResume' in rs) || !('difference' in rs))
            // missing or broken location review_summary
            return null

        if ('totalReviewCount' in rs)
            // New format after MAP-172
            return rs

        // Convert old format to new format
        return {
            difference       : rs.difference,
            googleResume     : rs.googleResume,
            answered         : rs.googleResume.answered,
            notAnswered      : rs.googleResume.notAnswered,
            totalReviewCount : rs.googleResume.answered + rs.googleResume.notAnswered,
            averageRating    : rs.googleResume.averageRating
        }
    }

    if (locations.length === 1) {
      return this.getRef(gid, locations[0].accountId, locations[0].locationId)
        .pipe(map(location => normalizeSummary(location?.review_summary)));
    } else if (locations.length > 1) {
      const $resumes = [];
      locations.forEach(p => {
        $resumes.push(
          this.getRef(gid, p.accountId, p.locationId)
            .pipe(map(location => normalizeSummary(location?.review_summary))));
      });
      return combineLatest($resumes);
    }
  }


  async locationRefreshAllDeps(accountId: string, locationId: string, gid: string /*, isExternal = false*/): Promise<boolean> {
    try {
      await (ENV.saveLocationInChain ? 
            this._saveInChain(accountId, locationId) : 
            zip(
                // TODO: All of this must be collapsed to a single backend endpoint
                this.checkLocation(gid, accountId, locationId),
                this.checkService(gid, accountId, locationId),
                this.saveInsights(accountId, locationId),
                this.saveReviews(accountId, locationId /*, isExternal*/),
                this.saveObservations(accountId, locationId),
                this.saveV3Posts(gid, accountId, locationId)
              )).toPromise();
      return true
    } catch (e) {
      console.error(`Error refreshing location gid=${gid} accountId=${accountId} locationId=${locationId}`, e);
      return false
    }
  }


  // TODO: utility functions must be moved out from an stateful service
  formatAddress(address): string {
    if (!address) 
      return ''
    
    return (`${address.addressLines !== undefined ? address.addressLines[0] : '--'} `+
            `${address.locality || ''}, ` +
            `${address.administrativeArea || ''} ` +
            `${address.postalCode || ''}`)
  }

  formatServiceArea(serviceArea: IServiceArea): string | null {
    if (!serviceArea) {
      return null;
    }
    
    const placeNames: string[] = [];

    serviceArea.places?.placeInfos?.forEach(place => {
      placeNames.push(place.placeName || '--')
    });
    
    return placeNames.join(' | ');
  }


  verifyOpen(periods) : any[] {
    if (!periods) {
      periods = [];
    }

    periods = JSON.parse(JSON.stringify(periods));
    const periodsResult = [];
    WEEK_DAYS.forEach(day => {
      const currentPeriods = periods.filter(period => period.openDay === day);
      for (const period of currentPeriods) {
        if (period) {
          const hoursPipe = new HoursAmPmPipe();
          const openTime = this._dateS.getStringHours(period.openTime);
          const closeTime = this._dateS.getStringHours(period.closeTime);
          period.openTime = hoursPipe.transform(openTime);
          period.closeTime = hoursPipe.transform(closeTime);
          if (period.open !== false) {
            period.open = true;
          }
          periodsResult.push(period);
        }

        if (period.open === false) {
          return;
        }
      }
      if (currentPeriods.length === 0) {
        periodsResult.push({
          openDay: day,
          closeDay: day,
          closeTime: '',
          openTime: '',
          open: false
        });
      }
    });
    return periodsResult;
  }

  sortPeriodsByDay(periods: WeekDays[]) {
    return periods.reduce((r, a) => {
      r[a.openDay] = r[a.openDay] || [];
      r[a.openDay].push(a);
      return r;
    }, {});
  }

  joinByDay(periods: any[]): any {
    const data = {};

    periods.forEach(el => {
      const date = `${el.startDate.day}/${el.startDate.month}/${el.startDate.year}`;
      
      if (Object.keys(data).includes(date)) {
        data[date].push(el);
      } else {
        data[date] = [el];
      }
    })

    return this._getContinuousSpecialHours(data)
  }

  getContinuousHours(periods: any[]): any[] {
    const data = [];
    
    periods.forEach((p, i) => {
      const day = {...p};
      const isOpen24 = (day.openTime === '12:00 AM' && day.closeTime === '12:00 AM');
      i = (day.openDay == 'SATURDAY' ? -1 : i)
      const nextDay = periods[i + 1];
      const nextDayIsOpen24 = (nextDay.openTime === '12:00 AM' && nextDay.closeTime === '12:00 AM');


      if (day.closeTime != '' && 
          p.closeTime == nextDay?.openTime && 
          !isOpen24 && 
          !nextDayIsOpen24 &&
          this._isBefore1230PM(nextDay.closeTime)
      ) {
        day.closeTime = nextDay?.closeTime;
        if(day.openDay == nextDay.openDay || nextDay.openDay == periods[i + 2]?.openDay || day.openDay == 'SATURDAY') {
          periods.splice(periods[i+1], 1);
        } else {
          nextDay.openTime = '';
          nextDay.closeTime = '';
          nextDay.open = false;
        }
        if (day.openDay == 'SATURDAY' && p.closeTime == nextDay.openTime) {
          if (data[1].openDay == 'SUNDAY') {
            data.splice(0, 1)
          } else {
            data[0].openTime = '';
            data[0].closeTime = '';
            data[0].open = false;
          }
        }
      }

      data.push(day);
    })
    return data;
  }

  private _isBefore1230PM(time: string): boolean {
    return time && (time === '12:00 PM' || time.includes('AM'))
  }

  fetchAccountLocations(gid : string, accountId : string): Promise<SavedLocation[]> {
    return this._http.get<SavedLocation[]>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}`).toPromise();
  }

  /**
   * Returns the same locations data passed as argument, filtered and augmented with:
   *   - If the location exists on the DB with the same gid/accountId, is filtered out.
   *   - If the location exists on the DB under other gid/accountId, is included in the result
   *     but a { exists : true} property is added.
   */
  filterOutLocationsAlreadyAdded<T extends {name : string}>(gid : string, accountId : string, locations : T[]): Promise<(T & {exists? : true})[]> {
    return this._http.post<any>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/getLocations`, locations).toPromise()
  }

  async isAllLocationsUltimate(locations): Promise<boolean> {
    const response = await this._http.post(
      `${ENV.apiUrl}/v2/locations/is-all-ultimate`,
      { locationPaths: locations }
    ).toPromise();
    return (response as any)?.data?.isAllLocationsUltimate;
  }

  async basicLocations(list: any[]): Promise<void> {
    const accountsLocations = [];
    await Promise.all(list.map(
      async (i) => {
        if (i.accounts) {
          if (i.accounts.length > 0) {
            await Promise.all(i.accounts.map(async (account) => {
              account.locationsBasics = [];
              const exists = accountsLocations.filter(al => al.accountId === account.accountId);
              if (exists.length === 0) {

                // FIXME: This method returns all locations, not only BASIC. It's ok?
                const basicLocations = await this.getBasicLocations(i.gid || this._sessionS.getSession().gid, account.accountId);
                accountsLocations.push({ [account.accountId]: basicLocations });
                account.locations.forEach(location => {
                  basicLocations.forEach(bl => {
                    if (bl.locationId === location.locationId) {
                      account.locationsBasics.push(bl);
                    }
                  });
                });
              } else {
                return;
              }
            }));
          } else if (i.gid && i.accountId && i.placeId) {
            const location  = this.getRef(i.gid, i.accountId, i.placeId);
            const asyncData = await Promise.all([location]);
            if (asyncData[0]) {
              await new Promise(resolve =>
                asyncData[0]
                  .subscribe(async (loc) => {
                    i.location = loc.data();
                    const asyncAccount = await loc.ref.parent.parent.get();
                    i.account = asyncAccount.data();
                    resolve(loc);
                  }));
            }
          }
        }
      }
    ));
  }

  async getBasicLocations(gid: string, accountId: string): Promise<SavedLocation[]> {
    // FIXME: This returns all locations, not only BASIC, it's ok? Redundant method if yes. 
    return await this.fetchAccountLocations(gid, accountId);
  }

  getByPrimaryCategory(gid: string, accountId: string, locationId: string): Observable<any> {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}/category`)
  }

  saveWidget(gid: string, accountId: string, locationId: string, data: any): Promise<void> {
    return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
      .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId)
      .set({ ...data }, { merge: true });
  }


  deleteWidget(gid: string, accountId: string, locationId: string): Promise<void> {
    return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
      .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId).ref.delete();
  }

/// TODO: Unused, remove?
//
//   getLocationsPaginate(count, pageable, actions): Pagination {
//     return this.formatPagination(count, pageable, actions);
//   }
//
//   formatPagination(count: number, pageable, actions): Pagination {
//     const pages = Math.ceil(count / pageable.size);
//     let hasPrev = true;
//     let hasNext = true;
//     if (pages === pageable.page && pages > 1) {
//       hasNext = false;
//       hasPrev = true;
//     } else if (pages === pageable.page && pages === 1) {
//       hasNext = false;
//       hasPrev = false;
//     } else if (pageable.page === 1 && pages !== 0) {
//       hasPrev = false;
//       hasNext = true;
//     } else if (pageable.page > 1 && pageable.page < pages) {
//       hasPrev = true;
//       hasNext = true;
//     } else {
//       hasPrev = false;
//       hasNext = false;
//     }
// 
//     return {
//       items: actions,
//       total: count,
//       per_page: pageable.size,
//       page:     pageable.page,
//       pages,
//       hasPrev,
//       hasNext,
//     }
//   }

  formatDates(date : string) : string {
    return date?.includes('T') ? date?.split('T')[0] : date?.split(' ')[0];
  }

  getDateValidations(reportType : string, accountIds : string[], gids : string[], locationIds : string[]): Promise<{minDate : string,
                                                                                                                    maxDate : string}> {
    const body = {
      "locationIds": locationIds,
      "accountIds": accountIds,
      "gids": gids,
      "type": reportType
      
     }
    return this._http.post<any>(`${ENV.apiUrl}/v2/locations/date-validation`, body).toPromise()
  }
  
  // TODO: Rethink this function, as it mutates the input parameter
  dateValidation(dates?: {minDate? : string, maxDate? : string }): { minDate: null | moment.Moment, 
                                                                     maxDate: null | moment.Moment } {
    const r = {minDate: null, 
               maxDate: null}

    if(dates?.maxDate) {
      dates.maxDate = `${dates.maxDate.split(' ')[0]}T23:59:59`;
      r.maxDate = moment(dates.maxDate);
    }
    
    if(dates?.minDate) {  
      dates.minDate = `${dates.minDate.split(' ')[0]}T23:59:59`;
      r.minDate = moment(dates.minDate);
    }
    return r
  }

  // TODO: Why this is on LocationService?
  buildDatepickerDate(reportType: string, maxDate? : moment.Moment, minDate? : moment.Moment) : { start : moment.Moment, 
                                                                                                  end   : moment.Moment } {
    const now = moment()

    const today = now.clone().subtract(7, 'days');
    const todayStr = today.format('YYYY-MM-DD 23:59:59')
    let endOfMonth = now.clone().endOf('month').format('YYYY-MM-DD 23:59:59');
    let isFullMonth = (todayStr == endOfMonth);

    // same month between minDate and maxDate validation (has to take into consideration that it might be a moment or a date)
    const isValidDate = (date: any) => date instanceof Date || moment.isMoment(date);
    const getMonth = (date: any) => {
      if (date instanceof Date) { 
        return date.getMonth(); 
      } else if (moment.isMoment(date)) {
        return date.format('YYYY-MM');
      } else {
        return null;
      }
    }
    const sameMonthBetweenMinAndMaxDate = isValidDate(minDate) && isValidDate(maxDate) && getMonth(minDate) == getMonth(maxDate);

    //Case 1: when the moth is complete
    let start = isFullMonth ? today.clone().subtract(1, 'year') : today.clone().subtract({ months: 1, years: 1 });
    let end   = isFullMonth ? today.clone()                     : today.clone().subtract({ months: 1 });

    // Case 2: if the month is incomplete
    if (!isFullMonth && (reportType?.includes('rollup') || reportType == 'performance-comparison') && maxDate) {
      // we create a clone of maxDate to prevent mutating the original date which caused SO many issues...
      const maxDateClone = maxDate.clone();
      const maxDateString = maxDateClone.format('YYYY-MM-DD 23:59:59')
      endOfMonth = maxDateClone.endOf('month').format('YYYY-MM-DD 23:59:59');
      isFullMonth = (maxDateString == endOfMonth);

      start = isFullMonth ? maxDateClone?.clone().subtract(1, 'year') : maxDateClone?.clone().subtract({ months: 1, years: 1 });
      end = isFullMonth || sameMonthBetweenMinAndMaxDate ? maxDateClone : maxDateClone?.clone().subtract({ months: 1 });
    }

    return { start: start.startOf('month'),
             end:   end.endOf('month')
           }
  }

  deleteServiceArea(accounts : any[]) : any[] {
    accounts?.forEach(acc => {
      acc?.locations.forEach(l => {
        if (Object.keys(l).includes('serviceArea')) {
          delete l.serviceArea;
        }
      })
    })

    return accounts;
  }

  deleteAddress(accounts : any[]) : any[] {
    accounts?.forEach(acc => {
      acc?.locations.forEach(l => {
        if ('address' in l) {
          delete l.address;
        }
      })
    })

    return accounts;
  }


  private _getContinuousSpecialHours(hours: any): any {
    const keys = Object.keys(hours);
    
    keys.forEach(d => {
      hours[d].forEach((h, i) => {
        const nextHour = hours[d][i+1];
        if(h.closeTime == nextHour?.openTime) {
          h.closeTime = nextHour?.closeTime;
          hours[d].splice(i+1, 1)
        }
      })
    })
    return hours;
  }

  private _saveInChain(accountId : string, locationId : string) {
    let params = new HttpParams();
    if (ENV.queuesEnabled) {
      params = params.append('enqueue', Queue.COMBINED_EXPRESS);
    }

    return this._http.post(`${ENV.apiUrl}/v2/locations/${accountId}/${locationId}/save`, {}, { params });
  }
}
