<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="updatingData || firstLoad">
  <mat-spinner></mat-spinner>
</div>

<app-reports-filter 
    [reportType]="'performance-insights'"
    [accountId]="accountId"
    [locationId]="locationId"
    (navigateBackEmitter)="goToLocations()"
></app-reports-filter>

<div id="charts-container" [class.view-without-padding]="!isReport" class="container--full container--padding-lg pb--0">
    <h2 *ngIf="reportName"><strong>{{reportName|uppercase}}</strong></h2>

    <div  *ngIf="!updatingData && insightsReportData?.stats?.length > 0" >
      <!-- <app-chart-multi [dataset]="multiChartDataSet" [commaNumbers]="true" [labels]="labels" [noDecimals]="true" *ngIf="multiChartDataSet?.length>0" (checks)="clearChecked($event)"></app-chart-multi> -->
      <ng-container *ngIf="insightsReportData?.stats">
        <ng-container *ngFor="let stat of insightsReportData?.stats">
          <app-chart-stats 
            *ngIf="stat?.totals > 0"
            [reportType]="typeReport"
            [stats]="stat"
            [labels]="stat?.labels"
            [granularity]="insightsReportData.granularity"
            [showRightProgressBar]="true"
            [hasDonutGraph]="false"
            [centerLegend]="true"
          ></app-chart-stats>
        </ng-container>

        <div class="col-12 mb--30 p--15">
          <div class="box box--rounded box--shadow bg--white row">
            <div class="align-items-center justify-content-start p--20 col-12">
              <h2 class="txt--md m--0 mb--15"><strong>Total Views</strong></h2>
              <p class="d--inl-block m--0 txt--price fw--regular">{{insightsReportData?.viewsData?.totals | number: '1.0-0'}}</p>
            </div>

            <div class="col-6" *ngFor="let stat of insightsReportData?.viewsData?.items">
              <app-chart-stats 
                *ngIf="stat?.totals > 0"
                [reportType]="typeReport"
                [stats]="stat"
                [labels]="insightsReportData?.viewsData?.labels"
                [granularity]="insightsReportData.granularity"
                [hasDonutGraph]="false"
                [centerLegend]="true"
                [hasMultipleChart]="true"
              ></app-chart-stats>
            </div>
          </div>
        </div>
        
        <!-- last two cards -->
        <div class="row">
          <div class="col-12 col-lg-6 mb--30">
            <app-reviews-average-rating [granularity]="insightsReportData?.granularity" [averageData]="insightsReportData?.averageData" *ngIf="insightsReportData?.granularity && insightsReportData?.averageData?.total > 0"></app-reviews-average-rating>
          </div>
          <div class="col-12 col-lg-6 mb--30">
            <app-reviews-number [granularity]="insightsReportData?.granularity" [reviewsData]="insightsReportData?.reviewsData" *ngIf="insightsReportData?.granularity && insightsReportData?.reviewsData?.total > 0"></app-reviews-number>
          </div>
        </div>

      </ng-container>
    </div>

    <div class="box--height mb--30"  *ngIf="(insightsReportData?.stats?.length === 0 && !updatingData)">
      <div class="box box--rounded box--shadow bg--white">
        <div class="box__header d-flex align-items-center justify-content-center">
          <h2 class="txt--capitalize"><strong>{{insightsDataMessage}}</strong></h2>
        </div>
      </div>
    </div>
</div>

<!-- to be reenabled once we work with reports -->
<!-- <app-table-multilocations 
      *ngIf="isReport"
      [reportType]="'performance-rollup'"
      [reportId]="reportId"
      [reportName]="reportName"
      [granularity]="reportDataPicker"
      [locations]="reportDataPicker?.locations"
      [refresh]="refresh" 
      [disableIcons]="shared && showMultilocations"
    >
</app-table-multilocations> -->






